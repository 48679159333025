import { LANGUAGE_ABBREVIATIONS } from './constants/languages';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
}

const enTranslationMessages = require('./translations/en.json');
const esTranslationMessages = require('./translations/es.json');
const frTranslationMessages = require('./translations/fr.json');
const deTranslationMessages = require('./translations/de.json');
const itTranslationMessages = require('./translations/it.json');
const manTranslationMessages = require('./translations/man.json');
const jaTranslationMessages = require('./translations/ja.json');
const porTranslationMessages = require('./translations/por.json');

const DEFAULT_LOCALE = LANGUAGE_ABBREVIATIONS.ENGLISH;

// prettier-ignore
const appLocales = [
  LANGUAGE_ABBREVIATIONS.ENGLISH,
  LANGUAGE_ABBREVIATIONS.SPANISH,
  LANGUAGE_ABBREVIATIONS.FRENCH,
  LANGUAGE_ABBREVIATIONS.GERMAN,
  LANGUAGE_ABBREVIATIONS.ITALIAN,
  LANGUAGE_ABBREVIATIONS.MANDARIN,
  LANGUAGE_ABBREVIATIONS.JAPANESE,
  LANGUAGE_ABBREVIATIONS.PORTUGUESE,
];

const formatTranslationMessages = (locale, messages) => {
  let defaultFormattedMessages;

  switch (locale) {
    case LANGUAGE_ABBREVIATIONS.SPANISH:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        esTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.FRENCH:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        frTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.GERMAN:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        deTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.ITALIAN:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        itTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.MANDARIN:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        manTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.JAPANESE:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        jaTranslationMessages
      );
      break;
    case LANGUAGE_ABBREVIATIONS.PORTUGUESE:
      defaultFormattedMessages = formatTranslationMessages(
        DEFAULT_LOCALE,
        porTranslationMessages
      );
      break;
    default:
      defaultFormattedMessages = {};
  }
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  [LANGUAGE_ABBREVIATIONS.ENGLISH]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.ENGLISH,
    enTranslationMessages
  ),
  es: formatTranslationMessages('es', esTranslationMessages),
  [LANGUAGE_ABBREVIATIONS.FRENCH]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.FRENCH,
    frTranslationMessages
  ),
  [LANGUAGE_ABBREVIATIONS.GERMAN]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.GERMAN,
    deTranslationMessages
  ),
  [LANGUAGE_ABBREVIATIONS.ITALIAN]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.ITALIAN,
    itTranslationMessages
  ),
  [LANGUAGE_ABBREVIATIONS.MANDARIN]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.MANDARIN,
    manTranslationMessages
  ),
  [LANGUAGE_ABBREVIATIONS.JAPANESE]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.JAPANESE,
    jaTranslationMessages
  ),
  [LANGUAGE_ABBREVIATIONS.PORTUGUESE]: formatTranslationMessages(
    LANGUAGE_ABBREVIATIONS.PORTUGUESE,
    porTranslationMessages
  ),
};

export {
  appLocales,
  formatTranslationMessages,
  translationMessages,
  DEFAULT_LOCALE,
};
