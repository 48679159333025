import { defineMessages } from 'react-intl';

export const scope = 'app_bar';

export default defineMessages({
  profileLink: {
    id: `${scope}.link.profile`,
    defaultMessage: 'Profile',
  },
  logoutLink: {
    id: `${scope}.link.logout`,
    defaultMessage: 'Logout',
  },
  notificationsLink: {
    id: `${scope}.link.notifications`,
    defaultMessage: 'Notifications',
  },
  languageSerbian: {
    id: `${scope}.link.serbian`,
    defaultMessage: 'Serbian',
  },
  languageEnglish: {
    id: `${scope}.link.english`,
    defaultMessage: 'English',
  },
  languageSpanish: {
    id: `${scope}.link.spanish`,
    defaultMessage: 'Spanish',
  },
  languageFrench: {
    id: `${scope}.link.french`,
    defaultMessage: 'French',
  },
  languageGerman: {
    id: `${scope}.link.german`,
    defaultMessage: 'German',
  },
  languageItalian: {
    id: `${scope}.link.italian`,
    defaultMessage: 'Italian',
  },
  languageMandarin: {
    id: `${scope}.link.mandarin`,
    defaultMessage: 'Mandarin',
  },
  languageJapanese: {
    id: `${scope}.link.japanese`,
    defaultMessage: 'Japanese',
  },
  languagePortuguese: {
    id: `${scope}.link.portuguese`,
    defaultMessage: 'Portuguese',
  },
});
