import messages from './messages';
import { LANGUAGE_ABBREVIATIONS } from '../../constants/languages';

export const languages = [
  {
    id: 1,
    value: LANGUAGE_ABBREVIATIONS.ENGLISH,
    label: messages.languageEnglish,
  },
  {
    id: 2,
    value: LANGUAGE_ABBREVIATIONS.SPANISH,
    label: messages.languageSpanish,
  },
  {
    id: 3,
    value: LANGUAGE_ABBREVIATIONS.FRENCH,
    label: messages.languageFrench,
  },
  {
    id: 4,
    value: LANGUAGE_ABBREVIATIONS.GERMAN,
    label: messages.languageGerman,
  },
  {
    id: 5,
    value: LANGUAGE_ABBREVIATIONS.ITALIAN,
    label: messages.languageItalian,
  },
  {
    id: 6,
    value: LANGUAGE_ABBREVIATIONS.MANDARIN,
    label: messages.languageMandarin,
  },
  {
    id: 7,
    value: LANGUAGE_ABBREVIATIONS.JAPANESE,
    label: messages.languageJapanese,
  },
  {
    id: 8,
    value: LANGUAGE_ABBREVIATIONS.PORTUGUESE,
    label: messages.languagePortuguese,
  },
];
