export const APP = '[App]';

export const FETCH_AUTHENTICATED_USER_REQUEST = `${APP} FETCH_AUTHENTICATED_USER_REQUEST`;
export const FETCH_AUTHENTICATED_USER_SUCCESS = `${APP} FETCH_AUTHENTICATED_USER_SUCCESS`;
export const LOGOUT_REQUEST = `${APP} LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${APP} LOGOUT_SUCCESS`;
export const SET_TOKEN = `${APP} SET_TOKEN`;
export const SESSION_EXPIRED = `${APP} SESSION_EXPIRED`;
export const FETCH_COMMON_DATA_REQUEST = `${APP} FETCH_COMMON_DATA_REQUEST`;
export const FETCH_COMMON_DATA_SUCCESS = `${APP} FETCH_COMMON_DATA_SUCCESS`;
export const UPDATE_AUTHENTICATED_USER = `${APP} UPDATE_AUTHENTICATED_USER`;

export const CHANGE_LOCALE_SAGA = `${APP} CHANGE_LOCALE_SAGA`;
