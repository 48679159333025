import React from 'react';
import LoginPage from 'containers/LoginPage/Loadable';
import ProjectListPage from 'containers/ProjectListPage/Loadable';
import SinglePatientPage from 'containers/SinglePatientPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import SingleProjectPage from 'containers/SingleProjectPage/Loadable';
import PublicRoute from 'containers/PublicRoute';
import PrivateRoute from 'containers/PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  HOME,
  LOGIN,
  PROJECT_LIST,
  SINGLE_PROJECT,
  SINGLE_PATIENT,
} from 'routes';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path={HOME}>
        <Redirect to={PROJECT_LIST} />
      </PrivateRoute>
      <PrivateRoute exact path={PROJECT_LIST} component={ProjectListPage} />
      <PrivateRoute exact path={SINGLE_PROJECT} component={SingleProjectPage} />
      <PrivateRoute exact path={SINGLE_PATIENT} component={SinglePatientPage} />
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
