import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_LIST } from 'routes';
import styles from './AppBar.module.scss';
import logoText from '../../assets/images/logo/logo-text.svg';
import profile from '../../assets/images/profile.png';
import arrowDown from '../../assets/images/arrow-down.svg';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors';
import { useIntl } from 'react-intl';
import messages from './messages';
import { languages } from './languages';

const AppBar = ({ user, onLogout, onLanguageChange }) => {
  const locale = useSelector(makeSelectLocale());

  const { formatMessage } = useIntl();

  const [langEl, setLangEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openLang = Boolean(langEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickLang = (event) => {
    setLangEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setLangEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.innerWrapper}>
          <Link to={PROJECT_LIST}>
            <img src={logoText} alt="logo" className={styles.image} />
          </Link>
          <div className={styles.profileWrapper}>
            <Button
              id="basic-button"
              aria-controls={openLang ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openLang ? 'true' : undefined}
              onClick={handleClickLang}
            >
              <p className={styles.languageText}>{locale}</p>
            </Button>
            <img
              src={profile}
              alt="profile"
              width={32}
              height={32}
              className={styles.image}
            />
            <span className={styles.name}>
              {user.first_name + ' ' + user.last_name}
            </span>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{
                minHeight: 24,
                maxHeight: 24,
                maxWidth: 24,
                minWidth: 24,
              }}
            >
              <img src={arrowDown} alt="arrow-down" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              disableScrollLock={true}
            >
              <MenuItem onClick={onLogout}>
                {formatMessage(messages.logoutLink)}
              </MenuItem>
            </Menu>
            <Menu
              id="basic-menu"
              anchorEl={langEl}
              open={openLang}
              onClose={handleCloseLang}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              disableScrollLock={true}
            >
              {languages.map((language) => {
                return (
                  <MenuItem
                    onClick={() => onLanguageChange(language.value)}
                    key={language.id}
                  >
                    {formatMessage(language.label)}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      </Container>
    </div>
  );
};

AppBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppBar;
